<template>
  <div id="main-contract-points-box">
    <AppTitle expandTitle titulo="Contrato de Serviço" />

    <div class="is-flex is-justify-content-center main-contract-points-container">
      <div class="main-contract-points-box">
        <div class="content">
          <p class="initial-section-text">
            Fizemos o máximo para simplificar o nosso contrato. Abaixo um breve resumo de alguns
            pontos importantes do contrato de serviço:
          </p>

          <p>
            Caso queira, você pode acessar o
            <a download :href="fileServer('contrato.pdf', 'files')" target="_blank"
              >contrato de serviço</a
            >
            completo.
          </p>

          <ul>
            <li class="initial-section-text">
              Os licitantes somente pagarão por contratos imediatos ou atas de registro de preço que
              forem <b>vencedores</b>.
            </li>

            <li class="initial-section-text">
              O não pagamento das taxas, sujeitam o licitante a sua inclusão nos Serviços de
              Proteção de Crédito, SERASA e cadastro de inadimplentes da SELCORP, bem como o
              bloqueio imediato do acesso, e posterior cancelamento de sua senha ou de chave
              eletrônica.
            </li>
          </ul>
        </div>

        <h1 class="title" id="table-title">Taxas de Contratos Imediatos</h1>

        <table id="custom-table">
          <tr class="table-row">
            <td class="table-text">Itens ou lotes até R$ 999.999,99</td>
            <td class="table-text second-column-text">
              1% sobre o valor total com teto de R$ 600,00
            </td>
          </tr>

          <tr class="table-row">
            <td class="table-text">Itens ou lotes a partir de R$ 1.000.000,00</td>
            <td class="has-text-centered table-text second-column-text">
              R$ 2.000,00
            </td>
          </tr>
        </table>

        <h1 class="title" id="table-title">Taxas de Atas de Registro de Preços</h1>

        <table id="custom-table">
          <tr class="table-row">
            <td class="table-text">Itens ou lotes até R$ 999.999,99</td>
            <td class="table-text second-column-text">
              0,75% sobre o valor total com teto de R$ 600,00
            </td>
          </tr>

          <tr class="table-row">
            <td class="table-text">Itens ou lotes a partir de R$ 1.000.000,00</td>
            <td class="table-text second-column-text">
              0,15% sobre o valor total com teto de R$ 2.000,00
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from '@/components/AppTitulo';

import { fileServer } from '@/utils/functions';

export default {
  name: 'MainContractPoints',
  components: {
    AppTitle,
  },
  data() {
    return {
      fileServer,
    };
  },
};
</script>

<style lang="scss" scoped>
#custom-table {
  width: 100%;
}

#table-title {
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
}

.initial-section-text {
  font-size: 16px;
}

.main-contract-points-box {
  margin: 3rem 0;
  max-width: 800px;
}

.second-column-text {
  max-width: 110px;
}

.table-row {
  border-bottom: 1px solid black;
  height: 70px;
}

.table-text {
  font-size: 15px;
}

table {
  td {
    vertical-align: inherit;
  }
}

ul li {
  margin: 15px 0;
}

@media (max-width: 1023px) {
  .main-contract-points-box {
    margin: 3rem;
  }
}

@media (max-width: 768px) {
  .table-row {
    height: 100px;
  }
}

@media (max-width: 577px) {
  .table-row {
    height: 120px;
  }
}

@media (max-width: 386px) {
  .table-row {
    height: 150px;
  }
}
</style>
